// @flow

import {getWordPair, isGameNumberCurrentGame, getEpochDayFromGameNumber} from './wordPairs4.js';
import {getNumDaysSinceEpoch} from './utils.js';
import {logEventWithGameNumber} from './loggingUtils.js';

/**
 * LOCAL STORAGE DETAILS
 * 
 * - userStats
 *  - numSolved: int // total number of games solved (does not include Archived games)
 *  - lastSolvedEpochDayStats: { // only recorded for last "today" solved (does not include Archived games)
 *    - epochDay: int,
 *    - minPathLength: int
 *    - minPathWords: [string]
 *  }
 *  - currentStreak: int // does not count Archived games
 *  - longestStreak: int // does not count Archived games
 * 
 * 
 * - gameStats
 *  - <gameNumber>: {
 *    - minPathLength
 *    - minPathWords
 *  }
 */

export function getDisplayStatsForGameNumber(gameNumber) {
  const userStats = getUserStats();
  const lastSolvedEpochDayStats = userStats['lastSolvedEpochDayStats'];
  const [startWord, endWord, optimalPathLength] = getWordPair(gameNumber);
  const minPathWords = getGameNumberSolvedMinPathWords(gameNumber);
  const minPathLength = minPathWords != null ? minPathWords.length : null;
  return {
    'numSolved': userStats['numSolved'],
    'minPathLength': minPathLength,
    'optimalPathLength': optimalPathLength,
    'startWord': startWord,
    'endWord': endWord,
    'minPathWords': minPathWords,
    'currentStreak':
      lastSolvedEpochDayStats != null && lastSolvedEpochDayStats['epochDay'] >= (getNumDaysSinceEpoch() - 1) ? 
        userStats['currentStreak'] :
        0,
    'longestStreak': userStats['longestStreak'],
  };
}

function getGameNumberSolvedMinPathWords(gameNumber) {
  const gameNumberStats = getGameNumberStats(gameNumber);
  const gameNumberStatsMinPathWords = gameNumberStats['minPathWords'];
  if (gameNumberStatsMinPathWords != null) {
    return gameNumberStatsMinPathWords;
  }
  // TODO: change this to depend only on "gameStats" once live for 1+ days
  const isTodaysGame = isGameNumberCurrentGame(gameNumber);
  if (!isTodaysGame) {
    return null;
  }
  const userStats = getUserStats();
  const lastSolvedEpochDayStats = userStats['lastSolvedEpochDayStats'];
  if (lastSolvedEpochDayStats == null) {
    return null;
  }
  const lastSolvedEpochDay = lastSolvedEpochDayStats['epochDay'];
  const gameNumberEpochDay = getEpochDayFromGameNumber(gameNumber);
  if (gameNumberEpochDay !== lastSolvedEpochDay) {
    return null;
  }
  const lastSolvedEpochDayStatsMinPathWords = lastSolvedEpochDayStats['minPathWords'];
  if (lastSolvedEpochDayStatsMinPathWords == null) {
    return null;
  }
  return lastSolvedEpochDayStatsMinPathWords;
}

export function getHasSolvedGameNumber(gameNumber) {
  const gameNumberStats = getGameNumberStats(gameNumber);
  if (gameNumberStats['minPathWords'] != null) {
    return true;
  }
  // TODO: change this to depend only on "gameStats" once live for 1+ days
  const isTodaysGame = isGameNumberCurrentGame(gameNumber);
  if (!isTodaysGame) {
    return false;
  }
  const userStats = getUserStats();
  const lastSolvedEpochDayStats = userStats['lastSolvedEpochDayStats'];
  if (lastSolvedEpochDayStats == null) {
    return false;
  }
  const lastSolvedEpochDay = lastSolvedEpochDayStats['epochDay'];
  const gameNumberEpochDay = getEpochDayFromGameNumber(gameNumber);
  return gameNumberEpochDay === lastSolvedEpochDay;
}

function getIsNewPathShorterThanExistingMinPath(gameNumber, newPathLength) {
  const gameNumberStats = getGameNumberStats(gameNumber);
  if (gameNumberStats['minPathLength'] != null) {
    if (newPathLength < gameNumberStats['minPathLength']) {
      return true;
    } else {
      return false;
    }
  }
  // TODO: change this to depend only on "gameStats" once live for 1+ days
  const isTodaysGame = isGameNumberCurrentGame(gameNumber);
  if (!isTodaysGame) {
    return false;
  }
  const userStats = getUserStats();
  const lastSolvedEpochDayStats = userStats['lastSolvedEpochDayStats'];
  if (lastSolvedEpochDayStats == null) {
    return false;
  }
  const lastSolvedEpochDay = lastSolvedEpochDayStats['epochDay'];
  const gameNumberEpochDay = getEpochDayFromGameNumber(gameNumber);
  if (gameNumberEpochDay !== lastSolvedEpochDay) {
    return false;
  }
  const existingMinPathLength = lastSolvedEpochDayStats['minPathLength'];
  return newPathLength < existingMinPathLength;
}

function getUserStats() {
  const userStats = JSON.parse(localStorage.getItem('userStats'));
  if (userStats == null) {
    return {
      'numSolved': 0,
      'lastSolvedEpochDayStats': null,
      'currentStreak': 0,
      'longestStreak': 0,
    };
  }
  return userStats;
}

function setUserStats(newUserStats) {
  localStorage.setItem(
    'userStats',
    JSON.stringify(newUserStats),
  );
}

function getGameNumberStats(gameNumber) {
  const gameStats = JSON.parse(localStorage.getItem('gameStats'));
  if (gameStats == null) {
    return {
      'minPathLength': null,
      'minPathWords': null,
    };
  }
  const gameNumberStats = gameStats[gameNumber];
  if (gameNumberStats == null) {
    return {
      'minPathLength': null,
      'minPathWords': null,
    };
  }
  return gameNumberStats;
}

function setGameNumberStats(gameNumber, enteredWords) {
  const gameStats = JSON.parse(localStorage.getItem('gameStats'));
  const newGameStats = gameStats != null ? getObjectDeepCopy(gameStats) : {};
  newGameStats[gameNumber] = {
    'minPathLength': enteredWords.length,
    'minPathWords': enteredWords,
  };
  localStorage.setItem(
    'gameStats',
    JSON.stringify(newGameStats),
  );
}

function logPuzzleSolve(gameNumber) {
  logEventWithGameNumber(gameNumber, 'puzzle_solve');
}

function getObjectDeepCopy(object) {
  return JSON.parse(JSON.stringify(object));
}

export function updateUserStatsWithWin(gameNumber, enteredWords) {
  logPuzzleSolve(gameNumber);

  const pathLength = enteredWords.length;
  const isTodaysGame = isGameNumberCurrentGame(gameNumber);
  const gameNumberEpochDay = getEpochDayFromGameNumber(gameNumber);
  const existingUserStats = getUserStats();

  if (getHasSolvedGameNumber(gameNumber)) {
    if (getIsNewPathShorterThanExistingMinPath(gameNumber, pathLength)) {
      setGameNumberStats(gameNumber, enteredWords);
      if (isTodaysGame) {
        const newUserStats = getObjectDeepCopy(existingUserStats);
        newUserStats['lastSolvedEpochDayStats'] = {
          'epochDay': gameNumberEpochDay,
          'minPathLength': pathLength,
          'minPathWords': enteredWords,
        };
        setUserStats(newUserStats);
      }
    }
    // If user is repeating a day's board and doesn't solve the board more optimally,
    // then we do not update the stats.
  } else {
    setGameNumberStats(gameNumber, enteredWords);
    const newUserStats = getObjectDeepCopy(existingUserStats);
    if (isTodaysGame) {
      newUserStats['numSolved'] = existingUserStats['numSolved'] += 1;
      newUserStats['lastSolvedEpochDayStats'] = {
        'epochDay': gameNumberEpochDay,
        'minPathLength': pathLength,
        'minPathWords': enteredWords,
      };
      const lastSolvedEpochDayStats = existingUserStats['lastSolvedEpochDayStats'];
      if (lastSolvedEpochDayStats == null) {
        newUserStats['currentStreak'] = 1;
        newUserStats['longestStreak'] = 1;
      } else {
        const lastSolvedEpochDay = lastSolvedEpochDayStats['epochDay'];
        const isStreakContinued = lastSolvedEpochDay === gameNumberEpochDay - 1;
        const newCurrentStreak = isStreakContinued ? existingUserStats['currentStreak'] + 1 : 1;
        newUserStats['currentStreak'] = newCurrentStreak;

        const existingLongestStreak = existingUserStats['longestStreak'];
        if (newCurrentStreak > existingLongestStreak) {
          newUserStats['longestStreak'] = newCurrentStreak;
        }
      }
    }
    setUserStats(newUserStats);
  }
}