// @flow

import {isGameNumberCurrentGame} from './wordPairs4.js';

export function setStartWordLocalStorage(gameNumber, startWord) {
  if (isGameNumberCurrentGame(gameNumber)) {
    localStorage.setItem('startWord', startWord);
  }
}

export function getStartWordLocalStorage(gameNumber) {
  if (isGameNumberCurrentGame(gameNumber)) {
    return localStorage.getItem('startWord');
  }
  return null;
}

export function setEndWordLocalStorage(gameNumber, endWord) {
  if (isGameNumberCurrentGame(gameNumber)) {
    localStorage.setItem('endWord', endWord);
  }
}

export function getEndWordLocalStorage(gameNumber) {
  if (isGameNumberCurrentGame(gameNumber)) {
    return localStorage.getItem('endWord');
  }
  return null;
}

export function setEnteredWordsLocalStorage(gameNumber, enteredWords) {
  if (isGameNumberCurrentGame(gameNumber)) {
    localStorage.setItem('enteredWords', JSON.stringify(enteredWords));
  }
}

export function getEnteredWordsLocalStorage(gameNumber) {
  if (isGameNumberCurrentGame(gameNumber)) {
    return JSON.parse(localStorage.getItem('enteredWords')) ?? [];
  }
  return [];
}

export function setIsGameOverLocalStorage(gameNumber, isGameOver) {
  if (isGameNumberCurrentGame(gameNumber)) {
    localStorage.setItem('isGameOver', isGameOver);
  }
}

export function getIsGameOverLocalStorage(gameNumber) {
  if (isGameNumberCurrentGame(gameNumber)) {
    return localStorage.getItem('isGameOver') === 'true';
  }
  return false;
}