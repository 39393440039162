// @flow

import * as React from 'react';

import './HeaderAdUnit.css';

class HeaderAdUnit extends React.Component {
  componentDidMount () {
    if (!this.props.isStaging) {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    }
  }

  render() {
    // const {isStaging} = this.props;

    return (
      <div className="headerAdUnit" data-fuse="23018547786"></div>
    );

    // if (isStaging) {
    //   return (
    //     <div className="headerAdUnit" data-fuse="23018547786"></div>
    //   );
    // } else {
    //   return (
    //     <ins className="adsbygoogle headerAdUnit"
    //       data-ad-client="ca-pub-1424527561781513"
    //       data-ad-slot="6976447280" />
    //   );
    // }
  }
}

export default HeaderAdUnit;