// @flow

import * as React from 'react';

import {getNextGameCountdownString} from './utils.js';

import './NextGameCountdown.css';

class NextGameCountdown extends React.Component {
  render() {
    window.setInterval(
      () => {
        const nextGameCountdownElement = document.getElementById('nextGameCountdownText');
        if (nextGameCountdownElement) {
          nextGameCountdownElement.textContent = getNextGameCountdownString();
        }
      },
      200,
    );
    return (
      <div id='nextGameCountdownText'>{getNextGameCountdownString()}</div>
    );
  }
}

export default NextGameCountdown;