// @flow

import React from 'react';

import './PrivacyPolicy.css';

export default function PrivacyPolicy() {
  return (
    <div>
        <div>
            California Consumer Privacy Act (“CCPA”)
        </div>
        <br/>
        <div>
            Under CCPA, Californian residents have the right to declare their preferences on the sale
            of data for advertising and marketing purposes. If you wish to change your preferences,
            click this link to launch our preference portal:
        </div>
        <br/>
        <div data-fuse-privacy-tool></div>
        <br/>
        <div>
            We use a third-party to provide monetisation technologies for our site. You can review their
            privacy and cookie policy at <a href="https://publift.com/privacy-policy/" target="_blank">https://publift.com/privacy-policy/</a>.
        </div>
    </div>
  );
}