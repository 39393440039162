// @flow

const MS_IN_SECOND = 1000;
const MS_IN_MINUTE = 60 * MS_IN_SECOND;
const MS_IN_HR = 60 * MS_IN_MINUTE;
const MS_IN_DAY = 24 * MS_IN_HR;
const UTC_TO_EST_MS = 5 * MS_IN_HR;

export function isAlphabetCharacter(character) {
  return character.length === 1 && character.match(/[a-z]/i);
}

export function getCharacterDiffCount(string1, string2) {
  let diffs = 0;
  string2.split('').forEach((character, index) => {
    if (character !== string1.charAt(index))
      diffs += 1;         
  });
  return diffs;
}

export function getNumDaysSinceEpoch() {
  return Math.floor((Date.now() - UTC_TO_EST_MS) / MS_IN_DAY);
}

export function getDateStringFromDaysSinceEpoch(daysSinceEpoch) {
  const date = new Date((daysSinceEpoch + 1) * MS_IN_DAY);
  return date.toLocaleDateString();
}

function getNumMillisecondsUntilNextGame() {
  return MS_IN_DAY - ((Date.now() - UTC_TO_EST_MS) % MS_IN_DAY);
}

export function getNextGameCountdownString() {
  const milliseconds = getNumMillisecondsUntilNextGame();

  const hours = Math.floor(milliseconds / MS_IN_HR);
  const minutes = Math.floor((milliseconds - (hours * MS_IN_HR)) / MS_IN_MINUTE);
  const seconds = Math.floor((milliseconds - (hours * MS_IN_HR) - (minutes * MS_IN_MINUTE)) / MS_IN_SECOND);
  
  const hoursString = hours === 0 ? '00' : hours < 10 ? '0' + hours : hours;
  const minutesString = minutes === 0 ? '00' : minutes < 10 ? '0' + minutes : minutes;
  const secondsString = seconds === 0 ? '00' : seconds < 10 ? '0' + seconds : seconds;

  return hoursString + ':' + minutesString + ':' + secondsString;
}