// @flow

export function logEventWithGameNumber(gameNumber, event) {
  if (typeof window.gtag !== 'undefined') {
    window.gtag("event", event, {
      event_category: 'general',
      event_label: gameNumber,
      value: 1,
    });
  }
}

export function logEvent(event) {
  if (typeof window.gtag !== 'undefined') {
    window.gtag("event", event, {
      event_category: 'general',
      value: 1,
    });
  }
}