// @flow

import React from 'react';
import ReactDOM from 'react-dom';
import {
  HashRouter as Router,
  Routes,
  Route,
  useLocation
} from "react-router-dom";

import ArchivedGame from './ArchivedGame';
import DailyGame from './DailyGame';
import PrivacyPolicy from './PrivacyPolicy';

ReactDOM.render(
  <Router>
    <App />
  </Router>,  
  document.getElementById('app-container')
);

function App() {
  const location = useLocation();

  /*
    Google AdSense adds #google_vignette to the URL to display vignette ads.
    This is an issue because we use HashRouter.

    When the URL is domain.com/#google_vignette, the hash is incorrectly interpreted as a path: 
      location.pathname = "google_vignette"
      location.hash = ""
    
    When the URL is domain.com/#/game/5/#google_vignette, however, the hash is correctly interpreted:
      location.pathname = "/game/5/"
      location.hash = "#google_vignette"
   
    Adding a Route with path "google_vignette" does not work.
  */
  if (location.pathname === "google_vignette") {
    return <DailyGame isArchive={false} />
  }

  return (
      <Routes>
        <Route path="/game/:gameNumber" element={<ArchivedGame />} />
        <Route path="/staging" element={<DailyGame isArchive={false} isStaging={true} />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/" element={<DailyGame isArchive={false} isStaging={false} />} />
      </Routes>
  );
}