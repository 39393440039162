// @flow

import {generatePath, useNavigate} from 'react-router-dom';
import {getCurrentGameNumber} from './wordPairs4.js';
import {logEvent} from './loggingUtils.js';

class GameNavigation {
  constructor(navigate) {
    this.navigate = navigate;
  }

  redirectToTodayGame() {
    logEvent('route_to_today');
    this.navigate("/");
    window.location.reload();
  }

  redirectToRandomGame() {
    logEvent('route_to_random');
    const numberOfGames = getCurrentGameNumber();
    const randomGameIndexNotIncludingToday = Math.floor(Math.random() * (numberOfGames - 1)); // 0-indexed
    const randomGameNumber = randomGameIndexNotIncludingToday + 1; // now 1 indexed
    const randomGamePath = generatePath("/game/:gameNumber", {
      gameNumber: randomGameNumber,
    });
    this.navigate(randomGamePath);
    window.location.reload();
  }

  redirectToPrivacyPolicy() {
    logEvent('route_to_privacy_policy');
    this.navigate("/privacy");
    window.location.reload();
  }
}

export default function useGameNavigation() {
  const navigate = useNavigate();
  return new GameNavigation(navigate);
}