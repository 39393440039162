// @flow

import React from 'react';
import {useParams} from "react-router-dom";

import DailyGame from './DailyGame';

export default function ArchivedGame() {
  const {gameNumber} = useParams();
  // param is of type string, must convert to number
  const gameNumberNumber = Number(gameNumber);
  return (
    <DailyGame gameNumber={gameNumberNumber} isArchive={true} isStaging={false} />
  );
}