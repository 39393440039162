// @flow

import React, { useState } from 'react';
import './index.css';
import HeaderAdUnit from './HeaderAdUnit';
import Game from './Game';
import GameHeader from './GameHeader';
import GameStatsModal from './GameStatsModal';
import HowToPlayModal from './HowToPlayModal';
import YesterdayModal from './YesterdayModal';
import {getCurrentGameNumber, getWordPair, getSpecialMessageData} from './wordPairs4.js';
import {getHasSolvedGameNumber} from './userStatsUtils.js';
import {logEventWithGameNumber} from './loggingUtils.js';
import useGameNavigation from './useGameNavigation.js';

export default function DailyGame(props) {
  let gameNumber = props.gameNumber;
  if (gameNumber == null) {
    gameNumber = getCurrentGameNumber();
  }
  const [startWord, endWord] = getWordPair(gameNumber);
  const hasSolvedToday = getHasSolvedGameNumber(gameNumber);

  const [isHowToPlayModalOpen, setIsHowToPlayModalOpen] = useState(false);
  const [isYesterdayModalOpen, setIsYesterdayModalOpen] = useState(false);
  const [isGameStatsModalOpen, setIsGameStatsModalOpen] = useState(hasSolvedToday);

  const gameNavigation = useGameNavigation();

  return (
    <div id='game-container'>
      <HeaderAdUnit 
        isStaging={props.isStaging}
      />
      <GameHeader
        gameNumber={gameNumber}
        isArchive={props.isArchive}
        onClickHowToPlay={() => {
          logEventWithGameNumber(gameNumber, 'click_how_to_play');
          setIsHowToPlayModalOpen(true);
        }}
        onClickRandomize={() => gameNavigation.redirectToRandomGame()}
        onClickReturnHome={() => gameNavigation.redirectToTodayGame()}
        onClickYesterday={() => {
          logEventWithGameNumber(gameNumber, 'click_yesterday');
          setIsYesterdayModalOpen(true);
        }}
        onClickUserStats={() => {
          logEventWithGameNumber(gameNumber, 'click_user_stats');
          setIsGameStatsModalOpen(true);
        }} 
      />
      <Game 
        startWord={startWord} 
        endWord={endWord}
        gameNumber={gameNumber}
        onGameLoad={() => setSpecialMessageToast(gameNumber)}
        openGameStatsModal={() => setIsGameStatsModalOpen(true)}
        setErrorToast={(message) => setErrorToast(message)}
        setSuccessToast={(message) => setSuccessToast(message)}
      />
      <HowToPlayModal
        isOpen={isHowToPlayModalOpen}
        onClose={() => setIsHowToPlayModalOpen(false)}
      />
      <YesterdayModal
        isOpen={isYesterdayModalOpen}
        onClose={() => setIsYesterdayModalOpen(false)}
      />
      <GameStatsModal
        gameNumber={gameNumber}
        isOpen={isGameStatsModalOpen} 
        onClose={() => setIsGameStatsModalOpen(false)} 
        onCopyToClipboard={() => setGenericToast('Copied to clipboard')}
      />
      <div className='toast aboveKeyboardToast' id='error-toast' />
      <div className='toast aboveKeyboardToast' id='success-toast' />
      <div className='toast belowHeaderToast' id='generic-toast' />
      <div className='toast belowHeaderToast' id='special-message-toast' />
    </div>
  );
}

function setErrorToast(message) {
  setToast('error-toast', message, 2000);
}

function setSuccessToast(message) {
  setToast('success-toast', message, 2000);
}

function setGenericToast(message) {
  setToast('generic-toast', message, 2000);
}

function setSpecialMessageToast(gameNumber) {
  const [specialMessage, specialMessageBackgroundColor] = getSpecialMessageData(gameNumber);
  if (specialMessage != null) {
    if (specialMessageBackgroundColor != null) {
      const specialMessageToastElement = document.getElementById('special-message-toast');
      specialMessageToastElement.style.setProperty('background-color', specialMessageBackgroundColor);
    }
    setToast('special-message-toast', specialMessage, 3000);
  }
}

function setToast(elementId, message, duration) {
  const element = document.getElementById(elementId);
  element.innerText = message;
  element.style.display = 'block';
  setTimeout(() => {
    element.style.display = 'none';
    element.innerText = null;
  }, duration);
}
