// @flow

import * as React from 'react';
import GameBlock from './GameBlock.js';
import GameModal from './GameModal.js';
import NextGameCountdown from './NextGameCountdown.js';
import {getDisplayStatsForGameNumber} from './userStatsUtils.js';
import {getCharacterDiffCount} from './utils.js';
import {isGameNumberCurrentGame, getSpecialEmoji} from './wordPairs4.js';
import {logEventWithGameNumber} from './loggingUtils.js';
import useGameNavigation from './useGameNavigation.js';

import './GameStatsModal.css';

const GameStatsModal = (props) => {

  const gameNavigation = useGameNavigation();

  function copyToClipboard(text) {
    var dummy = document.createElement("textarea");
    document.body.appendChild(dummy);
    dummy.value = text;
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);
    props.onCopyToClipboard();
  }

  function shareOrCopyToClipboard(gameNumber, text) {
    if (navigator.canShare && navigator.canShare({text: text})) {
      navigator
        .share({
          text: text,
        })
        .then(() => {
          logEventWithGameNumber(gameNumber, 'share_success');
        })
        .catch(error => {
          logEventWithGameNumber(gameNumber, 'share_failure' + String(error));
          copyToClipboard(text);
        });
    } else {
      logEventWithGameNumber(gameNumber, 'share_unsupported');
      copyToClipboard(text);
    }
  }

  function getEnteredWordsText(enteredWords, endWord) {
    let text = '';
    for (let i = 0; i < enteredWords.length; i++) {
      const enteredWord = enteredWords[i];
      const enteredWordEndWordCharacterDiffCount = getCharacterDiffCount(enteredWord, endWord);
      const numGreenSquares = endWord.length - enteredWordEndWordCharacterDiffCount;
      for (let j = 0; j < endWord.length; j++) {
        if (j < numGreenSquares) {
          text += '🟩';
        } else {
          text += '⬜';
        }
      }
      text += '\n';
    }
    return text;
  }

  function shareResultsOrCopyToClipboard(gameNumber, startWord, endWord, enteredWords) {
    

    let text = 'Weaver';

    const specialEmoji = getSpecialEmoji(gameNumber);
    text += specialEmoji != null ? specialEmoji : ' ';
    
    text += '#' + gameNumber + '\n\n';
    
    text += startWord.toUpperCase() + '\n';
    text += getEnteredWordsText(enteredWords, endWord);
    text += endWord.toUpperCase();

    shareOrCopyToClipboard(gameNumber, text);
  }

  function getPlayRandomGameButton() {
    return (
      <div className='playRandomGameButtonContainer'>
        <button className='playRandomGameButton' 
          onClick={() => gameNavigation.redirectToRandomGame()}>
          <div className='playRandomGameButtonText'>
            Random
          </div>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path 
              d="M12,11a1,1,0,1,0,1,1A1,1,0,0,0,12,11ZM8,7A1,1,0,1,0,9,8,1,1,0,0,0,8,7Zm9-5H7A5,5,0,0,0,2,7V17a5,5,0,0,0,5,5H17a5,5,0,0,0,5-5V7A5,5,0,0,0,17,2Zm3,15a3,3,0,0,1-3,3H7a3,3,0,0,1-3-3V7A3,3,0,0,1,7,4H17a3,3,0,0,1,3,3Zm-4-2a1,1,0,1,0,1,1A1,1,0,0,0,16,15Z"
            />
          </svg>
        </button>
      </div> 
    );
  }

  function getPlayTodayGameButton() {
    return (
      <div className='playTodayGameButtonContainer'>
        <button className='playTodayGameButton' 
          onClick={() => gameNavigation.redirectToTodayGame()}>
          <div className='playTodayGameButtonText'>
            Today
          </div>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path 
              d="M 12 2.0996094 L 1 12 L 4 12 L 4 21 L 11 21 L 11 15 L 13 15 L 13 21 L 20 21 L 20 12 L 23 12 L 12 2.0996094 z M 12 4.7910156 L 18 10.191406 L 18 11 L 18 19 L 15 19 L 15 13 L 9 13 L 9 19 L 6 19 L 6 10.191406 L 12 4.7910156 z"
            />
          </svg>
        </button>
      </div> 
    );
  }

  function getTodayModalContent(gameNumber) {
    const displayStats = getDisplayStatsForGameNumber(gameNumber);
    return (
      <div id='gameStatsModalContent'>
        <div className='statsModalRow'>
          {displayStats['minPathLength'] != null &&
            <div className='userStat'>
              <GameBlock character={displayStats['optimalPathLength']} classNames={['userStatsBlock']} />
              <div className='userStatLabel'>
                Today's Optimal
              </div>
            </div>
          }
          <div className='userStat'>
            {displayStats['minPathLength'] == null ?
              <GameBlock character='?' classNames={['userStatsBlock']} /> :
              <GameBlock character={displayStats['minPathLength']} classNames={['successUserStatsBlock']} />
            }
            <div className='userStatLabel'>
              Today's Score
            </div>
          </div>
          {displayStats['minPathLength'] != null &&
            <div className='shareTodaysScoreButtonContainer'>
              <button className='shareTodaysScoreButton' 
                onClick={() => 
                  shareResultsOrCopyToClipboard(
                    gameNumber,
                    displayStats['startWord'],
                    displayStats['endWord'],
                    displayStats['minPathWords'],
                  )}>
                <div className='shareButtonText'>
                  Share
                </div>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path d="M18 16.08c-.76 0-1.44.3-1.96.77L8.91 12.7c.05-.23.09-.46.09-.7s-.04-.47-.09-.7l7.05-4.11c.54.5 1.25.81 2.04.81 1.66 0 3-1.34 3-3s-1.34-3-3-3-3 1.34-3 3c0 .24.04.47.09.7L8.04 9.81C7.5 9.31 6.79 9 6 9c-1.66 0-3 1.34-3 3s1.34 3 3 3c.79 0 1.5-.31 2.04-.81l7.12 4.16c-.05.21-.08.43-.08.65 0 1.61 1.31 2.92 2.92 2.92s2.92-1.31 2.92-2.92c0-1.61-1.31-2.92-2.92-2.92zM18 4c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zM6 13c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zm12 7.02c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1z" />
                </svg>
              </button>
            </div>
          }
        </div>
        <div className='statsModalRow'>
          <div className='userStat'>
            <GameBlock character={displayStats['numSolved']} classNames={['userStatsBlock']} />
            <div className='userStatLabel'>
              Solved
            </div>
          </div>
          <div className='userStat'>
            <GameBlock character={displayStats['longestStreak']} classNames={['userStatsBlock']} />
            <div className='userStatLabel'>
              Longest Streak
            </div>
          </div>
          <div className='userStat'>
            <GameBlock character={displayStats['currentStreak']} classNames={['userStatsBlock']} />
            <div className='userStatLabel'>
              Current Streak
            </div>
          </div>
        </div>
        <div className='statsModalRow'>
          <div className='nextGameCountdownContainer'>
            <NextGameCountdown />
            <div>Next Weaver</div>
          </div>
        </div>
        <div className='statsModalRow'>
          {getPlayRandomGameButton()}
        </div>
      </div>
    );
  }

  function getArchivedModalContent(gameNumber) {
    const displayStats = getDisplayStatsForGameNumber(gameNumber);
    return (
      <div id='gameStatsModalContent'>
        <div className='statsModalRow'>
          {displayStats['minPathLength'] != null &&
            <div className='userStat'>
              <GameBlock character={displayStats['optimalPathLength']} classNames={['userStatsBlock']} />
              <div className='userStatLabel'>
                Optimal
              </div>
            </div>
          }
          <div className='userStat'>
            {displayStats['minPathLength'] == null ?
              <GameBlock character='?' classNames={['userStatsBlock']} /> :
              <GameBlock character={displayStats['minPathLength']} classNames={['successUserStatsBlock']} />
            }
            <div className='userStatLabel'>
              Score
            </div>
          </div>
          {displayStats['minPathLength'] != null &&
            <div className='shareTodaysScoreButtonContainer'>
              <button className='shareTodaysScoreButton' 
                onClick={() => 
                  shareResultsOrCopyToClipboard(
                    gameNumber,
                    displayStats['startWord'],
                    displayStats['endWord'],
                    displayStats['minPathWords'],
                  )}>
                <div className='shareButtonText'>
                  Share
                </div>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path d="M18 16.08c-.76 0-1.44.3-1.96.77L8.91 12.7c.05-.23.09-.46.09-.7s-.04-.47-.09-.7l7.05-4.11c.54.5 1.25.81 2.04.81 1.66 0 3-1.34 3-3s-1.34-3-3-3-3 1.34-3 3c0 .24.04.47.09.7L8.04 9.81C7.5 9.31 6.79 9 6 9c-1.66 0-3 1.34-3 3s1.34 3 3 3c.79 0 1.5-.31 2.04-.81l7.12 4.16c-.05.21-.08.43-.08.65 0 1.61 1.31 2.92 2.92 2.92s2.92-1.31 2.92-2.92c0-1.61-1.31-2.92-2.92-2.92zM18 4c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zM6 13c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zm12 7.02c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1z" />
                </svg>
              </button>
            </div>
          }
        </div>
        <div className='statsModalRow'>
          <div className='archivedModalPlayRandomGameButtonContainer'>
            {getPlayRandomGameButton()}
          </div>
          {getPlayTodayGameButton()}
        </div>
      </div>
    );
  }

  function getModalContent(gameNumber) {
    if (isGameNumberCurrentGame(gameNumber)) {
      return getTodayModalContent(gameNumber);
    } else {
      return getArchivedModalContent(gameNumber);
    }
  }

  return (
    <GameModal
      title='Statistics'
      content={getModalContent(props.gameNumber)}
      isOpen={props.isOpen}
      onClose={props.onClose}
    />
  );
}

export default GameStatsModal;